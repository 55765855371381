.App {
  text-align: center;
}

#letters input {
  height: 58px;
  font-size: 20px;
}

#letters {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.letter_list {
  border-radius: 6px;
  border: 1px solid black;
  padding-left: 10px;
  margin: 16px;
}

/* Gevonden letters */

#placed_letters {
  margin: 16px;
}

.placed_letter {
  border: 1px solid black;
  border-left: 0px;
  width: 58px;
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
  
}

#letter1 {
  border-left: 1px solid black;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

#letter5 {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

/* Gevonden woorden */

#words {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

#words ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: flex-start;
}

#words li {
  margin: 10px;
  padding: 5px;
  border: 1px solid black;
  border-radius: 2px;
}